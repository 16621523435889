.login-form {
  flex: 6;
  height: calc(100vh);
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;

  .error {
    color: red;
    font-size: 0.9rem;
  }

  .rigimage {
    position: absolute;
    top: auto;
    bottom: 0%;
    left: auto;
    right: 0%;
  }

  .smallheader {
    letter-spacing: .08em;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.8;
  }

  .loginbutton {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    background-color: #ff6e00;
    color: #fff;
    text-align: center;
    letter-spacing: -.02em;
    border-radius: 5px;
    flex-direction: row;
    flex: none;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    padding: 12px 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
    transition: all .5s cubic-bezier(.6, .6, 0, 1);
  }

  .loginbutton:hover {
    background-color: #ff9543;
  }

  .loginbutton:active {
    background-color: #e26200;
  }


  form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    z-index: 1;

    .inputItem {
      width: 300px;

      input {
        width: 100%;
        border-radius: 4px;
        padding: 0.5rem 0.7rem;
        border: 1px solid lightgray;
      }
    }

    button {
      padding: 0.5rem 1rem;
      font-weight: 700;
      font-size: medium;
      background-color: rgba(94, 17, 138, 0.63);
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
    }
  }
}