.card {
  padding-top: 40px;
  padding-left: 25px;
  padding-right: 25px;


  .group-hero {
    grid-column-gap: 50px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    display: flex;
  }

  .group-hero_l {
    grid-column-gap: 10px;
    align-items: center;
    display: flex;
  }

  .group-hero_text {
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    max-width: 350px;

  }

  .group-hero_text.light {
    color: #7b8ca0;
  }

  .left,
  .right {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .left {
    .title {
      text-transform: uppercase;
      color: gray;
      font-weight: bold;
    }

    .content {
      font-size: 1.7rem;
    }

    .footer {
      font-size: 0.8rem;
      text-decoration: underline;
      cursor: pointer;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .right {
    color: gray;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.9rem;
    }

    .icon {
      align-self: flex-end;
      width: 32px;
      height: 32px;
      border-radius: 5px;
    }
  }

  .positive {
    color: green;
  }

  .negative {
    color: red;
  }
}