.DataTable {
  padding: 1rem;

  
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2rem;
    background: #333;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    
    div {
      padding: 0.5rem 1rem;
      border-radius: 4px;
      background: #ffffff;
      color: #333;
      cursor: pointer;
    }
  }

  .MuiDataGrid-root {
    background: #ffffff;
    border-radius: 8px;
  }

  .MuiDataGrid-columnHeaders {
    background-color: #333;
    color: white;
    font-weight: bold;
    font-size: 0.95rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .MuiDataGrid-cell {
    font-size: 0.9rem;
    color: #333;
  }

  .MuiDataGrid-row {
    &:nth-child(even) {
      background-color: #f5f5f5;
    }
  }

  .MuiDataGrid-row:hover {
    background-color: #e0e0e0;
  }

  .cellWithImage {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .cellWithStatus {
    font-weight: 500;
    text-align: center;
    border-radius: 4px;
    padding: 4px 8px;
    &.Loading {
      color: goldenrod;
      background-color: rgba(218, 165, 32, 0.247);
    }
    &.Active {
      color: green;
      background-color: rgba(0, 128, 0, 0.226);
    }
    &.Inactive {
      color: crimson;
      background-color: rgba(220, 20, 60, 0.349);
    }
  }

  .cellAction {
    display: flex;
    gap: 10px;
    align-items: center;
    
    .actionBtnDelete {
      color: crimson;
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid rgba(220, 20, 60, 0.281);
      cursor: pointer;
    }
  }

  /* Search input styling */
  .search-input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
}
