.home {
  width: 100%;


  .navigation {
    z-index: 999;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    height: 109px;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .navigation-right {
    grid-column-gap: 40px;
    align-items: center;
    display: flex;
  }

  .user-wrap.res-hide {
    display: none;
  }

  .user-icon {
    object-fit: cover;
    border-radius: 50%;
    width: 48px;
    height: 48px;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
  }

  .user {
    grid-column-gap: 15px;
    align-items: center;
    display: flex;
  }

  .user-name {
    margin-bottom: 3px;
    font-weight: 600;
    text-align: center;
  }

  .embed-icon {
    margin-bottom: -3px;
  }

  .button {
    grid-column-gap: 8px;
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
    border-radius: 5px;
    align-items: center;
    padding: 10px 11px;
    text-decoration: none;
    transition: color .2s, background-color .2s;
    display: flex;
  }

  .button:hover {
    background-color: var(--background-light);
    color: #000;
  }

  .user-description {
    font-size: 12px;
    text-align: center;
  }

  .user-wrap.small-gap {
    grid-column-gap: 15px;
  }

  .listtable {
    padding: 1rem;
    margin: 1rem 0.5rem;
    box-shadow: 2px 4px 11px 4px rgba(88, 84, 84, 0.2);

    .title {
      color: gray;
    }
  }
}