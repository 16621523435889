.notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%) translateY(-100%); 
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000; 
  opacity: 0.9; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.notification.success {
  background-color: #4caf50;
  color: white; 
  animation: slideIn 0.5s forwards; 
}

.notification.error {
  background-color: #f44336;
  color: white; 
  animation: slideIn 0.5s forwards; 
}

.notification.loading {
  background-color: #f44336; 
  color: white; 
  animation: slideIn 0.5s forwards; 
}

@keyframes slideIn {
  from {
    transform: translateX(-50%) translateY(-100%); 
    opacity: 0; 
  }
  to {
    transform: translateX(-50%) translateY(0); 
    opacity: 1; 
  }
}
