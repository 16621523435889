@import "../color.scss";

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff6e00;
  z-index: 9999;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease, visibility 0s linear 0.5s;
}

#preloader.fade-out {
  opacity: 0;
  visibility: hidden;
}

.loader {
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-top: 5px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#root {
  transition: all 0.1s;
  display: flex;
  width: 100vw;

  a {
    text-decoration: none;
    color: inherit;
  }

  .dark {
    background-color: $primary-dark;
    color: $text-dark;
  }

  .contentWrap {
    flex: 6;
    height: 100vh;
    background-color: #f8f8f8;
  }
}