.new {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .content {
    display: flex;
    max-width: 800px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .left,
  .right {
    padding: 20px;
  }

  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .left img {
    max-width: 150px;
    max-height: 150px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }

  .right {
    flex: 2;
  }

  .right .error {
    color: red;
    margin-bottom: 10px;
  }

  .inputItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .inputItem label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .inputItem input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }

  .inputItem input:focus {
    border-color: #007BFF;
    outline: none;
  }

  .inputItem.file label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .remove-button {
    grid-column-gap: 8px;
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
    border-radius: 5px;
    align-items: center;
    padding: 10px 11px;
    margin-top: 10px;
    text-decoration: none;
    transition: color .2s, background-color .2s;
    display: flex;
  }

  .remove-button.center {    text-align: center;

    justify-content: center;
  }

  .remove-button:hover {
    background-color: var(--background-light);
    color: #000;
  }

  .inputItem.file img {
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }

  @media (max-width: 991px) {
    .content {
      display: block;
    }
  }
}
