@import "../../../color.scss";

.sidebar {
  background-color: #e9e9e9;
  flex-direction: column;
  justify-content: space-between;
  min-width: 250px;
  width: 250px;
  display: flex;
  position: sticky;
  top: 0;
  transition: width 0.3s ease; 
  height: 100vh;

  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }

  .logo-link {
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 20px;
  }

  .center li {
    grid-column-gap: 14px;
    color: #000;
    border-radius: 7px;
    align-items: center;
    margin-bottom: 10px;
    padding: 13px 20px;
    text-decoration: none;
    transition: color .2s, background-color .2s;
    display: flex;
  }

  .activeClassName li {
    color: #fff;
    background-color: #000;
  }

  .icon {
    margin-bottom: -3px;
  }

  .nav-bot {
    padding-left: 25px;
    padding-right: 25px;
  }

  .nav-link_text {
    font-weight: 600;  
    font-family: Outfit, sans-serif;
    font-size: 14px;
    line-height: 1.2;
  }

  .nav-separator {
    background-color: #000;
    width: 100%;
    height: 1px;
    margin-top: 25px;
    margin-bottom: 20px;
  }

  .nav-menu {
    padding-left: 25px;
    padding-right: 25px;
  }

  // Media query for mobile view
  @media (max-width: 991px) {
    min-width: 48px;           // Set sidebar width to 45px on mobile

    max-width: 48px;           // Set sidebar width to 45px on mobile
    .nav-link_text {
      display: none;       // Hide text on mobile
    }
    .nav-menu {
      padding-left: 5px;
      padding-right: 5px;
    }
    .logo-link {
      display: none;       // Hide logo on mobile
    }

    .icon {
      justify-content: center; 
      margin-bottom: -4px;
 
    }
    .w-embed{
      justify-content: center;
      margin-left: -18px;  
    }
    .nav-separator {
      height: 0px;
      margin-top: 5px;
      margin-bottom: 0px;

    }
    .center li {
      justify-content: center;  // Center items in the list
      padding: 10px;           // Adjust padding for better fit
    }
  }
}
